import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom'; 
import './frontpage.css'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const images = [
    '/background/greybrownbackground.png',
    '/background/newbackground.png',
    '/background/blackbrownbackground.jpg',
];


function RenderFrontPage(){
    const navigate= useNavigate();
    const [places, setPlaces] = useState([]); 
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_TOP_PLACES_URL);
                setPlaces(response.data || []); // Ensure data is an array
            } catch (error) {
                console.error("Error fetching data:", error);
                setPlaces([]); // Handle error by setting an empty array
            }
        };
        fetchData();
    }, []);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    useEffect(() => {
        const interval = setInterval(nextImage, 5000); // Change image every 5 seconds
        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    // Only create placesWithImages if places are available and not empty
    const placesWithImages = places.length > 0 ? places.slice(0, 5).map(place => ({
        name: place.placeParentName,
        imageUrl: `${process.env.REACT_APP_PLACES_IMAGES_URL}${place.placeParentName}.png`
    })) : [];

    useEffect(() => {
        let next = document.querySelector('.next');
        let prev = document.querySelector('.prev');

        const handleNext = () => {
            const items = document.querySelectorAll('.item');
            document.querySelector('.sliding-window').appendChild(items[0]); // Move the first item to the end
        };
    
        const handlePrev = () => {
            const items = document.querySelectorAll('.item');
            document.querySelector('.sliding-window').prepend(items[items.length - 1]); // Move the last item to the front
        };
    
        // Attach event listeners
        next.addEventListener('click', handleNext);
        prev.addEventListener('click', handlePrev);

        // Clean up event listeners when the component is unmounted
        return () => {
            next.removeEventListener('click', handleNext);
            prev.removeEventListener('click', handlePrev);
        };
    }, []);

    return (
            <div className='frontpage-container'>
                <div className='frontpage-1'>
                    <div className='frontpage-title'>Design the journey you’ve always dreamed of</div>
                    <div className='frontpage-search'>
                        <div class="destination">
                            <p>Where To?</p>
                            <input type="text" className="search-input" placeholder="Place Name" />
                        </div>
                        <div class="time">
                            <p>When?</p>
                            <input type="text" className="search-input" placeholder="mm/dd/yyyy" />
                        </div>
                        <div class="days">
                            <p>How many Days?</p>
                            <input type="text" className="search-input" placeholder="days" />
                        </div>
                        <div className='submit-button-container'>
                            <button type="submit" className="submit-button">Find Now</button>
                        </div>
                    </div>
                </div> 
                <div className='frontpage-2'>
                    <div className='frontpage2-innerdiv'> 
                        <div className='frontpage2-left'>
                            <div className='slideshow'>
                                <img src={images[currentIndex]} alt="Slideshow" className='slideshow-image' />
                                <button className='prev-button' onClick={prevImage}>❮</button>
                                <button className='next-button' onClick={nextImage}>❯</button>
                            </div>
                            <div className='dots'>
                            {images.map((_, index) => (
                                <span
                                    key={index}
                                    className={`dot ${currentIndex === index ? 'active' : ''}`}
                                    onClick={() => setCurrentIndex(index)}
                                ></span>
                            ))}
                        </div>
                        </div>
                        <div className='frontpage2-right'>
                            <div className='frontpage2-title'>Unleash your wanderlust and let us craft personalized itineraries tailored just for you.</div>
                            <div className='frontpage2-desc'>
                                At India Bharat Travel, we understand that no two journeys are the same. Whether you’re planning a quick weekend getaway or an epic cross-country adventure, our platform helps you create the perfect itinerary for the places you want to visit. 
                                Simply tell us where and when, and we’ll handle the rest. With seamless planning tools, customized recommendations, and expert insights, your dream vacation is just a few clicks away.
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='frontpage-3'>
                    <div className='frontpage3-innerdiv'> 
                        <div className='sliding-window'>
                            {placesWithImages.map((place) => (
                                <div 
                                className='item' 
                                style={{
                                    backgroundImage: `url(${place.imageUrl})`,
                                }}>
                                    <div className='content'>
                                        <div className='name'>{place.name}</div>
                                        <button className='submit-button' onClick= { () => navigate(`/places/name/${place.name}`) }> See More</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div class="button">
                            <button class="prev"><i class="fa-solid fa-arrow-left"></i></button>
                            <button class="next"><i class="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className='frontpage-4'>
                    <div className='frontpage4-innerdiv'> 
                        <div className='footer-links'>
                            <Link to="/about-us" className='footer-link'>About Us</Link>
                            <Link to="/privacy-policy" className='footer-link'>Privacy Policy</Link>
                            <Link to="/disclaimer" className='footer-link'>Disclaimer</Link>
                            <Link to="/contact-us" className='footer-link'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default RenderFrontPage;