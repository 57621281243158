import './App.css';
import { RenderStatesNavBar } from './navbar/states';
import { RenderSiteTitle } from './common/renderSiteTitle';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import PlacesPage from './places/placesView';
import { StateNameProvider } from './context/stateContext';
import RenderFrontPage  from './frontpage/frontpage';
import PlacesParentPage from './places/placesParent';

function AppContent() {
  const location = useLocation();
  const isPlacesPage = location.pathname.startsWith('/places/');

  return (
    <div className={`app-container ${isPlacesPage ? 'black-background' : 'display-page'}`}>
      <StateNameProvider>
        <RenderSiteTitle />
        <div className="pagecontent">
          <RenderStatesNavBar />
          <Routes>
            <Route path='/' element={<RenderFrontPage />} />
            <Route path='/places/:stateId' element={<PlacesPage />} />
            <Route path='/places/name/:placename' element={<PlacesParentPage />} />
            <Route path='/about' element={<RenderFrontPage />} />
            <Route path='/privacy-policy' element={<RenderFrontPage />} />
            <Route path='/disclaimer' element={<RenderFrontPage />} />
            <Route path='/contact-us' element={<RenderFrontPage />} />
          </Routes>
        </div>
      </StateNameProvider>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;