import React, { useState, useEffect } from 'react';
import axios from 'axios'
import './states.css'
import { useNavigate } from 'react-router-dom';
import { useStateName } from '../context/stateContext';



function FetchStates () {
    const [data, setData] = useState(null);
    useEffect( () => {
        const fetchData= async()=>{
            try{
                console.log("process.env.STATES_URL: "+process.env.REACT_APP_STATES_URL)
                const response = await axios.get(process.env.REACT_APP_STATES_URL);
                console.log("response: "+response.data);
                setData(response.data);
            }catch (error){
                console.error("error fetching data : ",error);
            }
        }
        fetchData();
    }, []);
    return data;
}

export function RenderStatesNavBar(){
    console.log("inside render states navbar");
    const navigate= useNavigate();
    const { setStateName } = useStateName();
    const [isVisible, setIsVisible] = useState(true);
    
    function fetchAllPlaces(stateId, stateName){
        console.log("fetching places for stateId: "+stateId);
        setStateName(stateName); 
        navigate(`/places/${stateId}`)
    }


    const data = FetchStates();
    console.log("data:", data);
    
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
            <div className={`states-container ${isVisible ? '' : 'hidden'}`}>
                <div className={`navbar ${isVisible ? '' : 'hidden'}`}>
                    
                    {data && Array.isArray(data) && (
                        data.map (state => (
                            <button id={state.stateId} className="stateName" onClick= { () => fetchAllPlaces(state.stateId, state.stateName) }> {state.stateName} </button>
                        ))
                        
                    )}
                </div>
                <button className="toggle-button" onClick={toggleVisibility}>
                    {isVisible ? '<' : 'Explore States'}
                </button>
            </div> 
    )
}
